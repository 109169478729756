import dayjs from 'dayjs'
import { showToast } from '@/http/message'
import i18n from "@/lang/index"
export const $Info = {
  msg: (val) => {
    showToast(val)
  },
  success: (val) => {
    showToast(val, i18n.t('成功'), 'success')
  },
  warning: (val) => {
    showToast(val, i18n.t('警告'), 'warning')
  },
  error: (val) => {
    showToast(val, i18n.t('错误'), 'error')
  },
  info: (val) => {
    showToast(val, i18n.t('提示'), 'info')
  },
}
export const publicRule = {
  // eslint-disable-next-line camelcase
  filer_time_define: (val, fmt) => {
    if (!val) {
      return val
    }
    return dayjs(val).format(fmt)
  },
  time: val => {
    return publicRule.filer_time_define(val, 'YYYY-MM-DD HH:mm:ss')
  },
  // eslint-disable-next-line camelcase
  time_ymd: val => {
    return publicRule.filer_time_define(val, 'YYYY-MM-DD')
  },
  // eslint-disable-next-line camelcase
  time_ym: val => {
    return publicRule.filer_time_define(val, 'YYYY-MM')
  },
  // eslint-disable-next-line camelcase
  time_y: val => {
    return publicRule.filer_time_define(val, 'YYYY')
  },
  filerArray: (array, value) => {
    const result = array.find(item => {
      return item == value
    })
    return !publicRule.isValue(result)
  },
  joinArray: (array, key) => {
    return array.map(x => x[key]).join()
  },
  amount: argNum => {
    let num = (argNum || 0).toString()
    let number = 0,
      floatNum = '',
      intNum = ''
    // 判断是否有小数位，有则截取小数点后的数字
    if (num.includes('.')) {
      number = num.indexOf('.') // 获取小数点出现的位置
      floatNum = num.substr(number, 3) // 截取arr.substr(form, length)
      intNum = num.substring(0, number) // 截取arr.substring(start, end)
    } else {
      intNum = num
    }
    let result = [],
      counter = 0
    intNum = intNum.split('')
    // 利用3的倍数，向数组插入','
    for (let i = intNum.length - 1; i >= 0; i--) {
      counter++
      result.unshift(intNum[i])
      if (!(counter % 3) && i != 0) {
        result.unshift(',')
      }
    }
    return result.join('') + floatNum || ''
  },
  toPercent: num => {
    const value = num || 0
    let str = Number(value * 100).toFixed(2)
    str += '%'
    return str
  },
  priceFormat: (val) => {
    if (val) {
      let price = parseFloat(val).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return price
    } else {
      return '0.00'
    }
  },
  priceFormat3: (val, fixed = 4) => {
    if (val) {
      let price = parseFloat(val).toFixed(fixed).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
      return price
    } else {
      return '0'
    }
  },
  // 不要改
  isValue: (val) => {
    if (val === '' || val === null || val === void 0) return true
    return false
  },
}
