<template>
  <ElTable
    ref="table"
    :data="data"
    stripe
    size="small"
    style="width: 100%"
    :max-height="height"
    :header-cell-style="headerCellStyle"
    :cell-style="cellStyle"
    v-bind="$attrs"
    :show-summary="showSummary"
    :summary-method="summary"
    @selection-change="selectionChange"
  >
    <ElTableColumn
      v-if="index"
      type="index"
      :fixed="fixed"
      :label="$t('序号')"
      align="center"
      width="50px"
    />
    <ElTableColumn
      v-if="selection"
      type="selection"
      align="center"
      :fixed="fixed"
      width="46px"
    />
    <ElTableColumn
      v-if="showAction"
      type="action"
      :fixed="fixed"
      align="center"
      :width="actionWidth"
    >
      <template #default="{ row, column, $index }">
        <slot
          name="action"
          :row="row"
          :index="$index"
          :column="column"
        />
      </template>
    </ElTableColumn>
    <slot />
    <ElTableColumn
      v-for="column1 of columns"
      :key="column1.prop"
      :prop="column1.prop"
      min-width="120px"
      v-bind="column1"
    >
      <template v-if="$slots[column1.field]" #default="{ row, column, $index }">
        <slot
          :name="column.field"
          :row="row"
          :index="$index"
          :column="column"
        />
      </template>
      <template v-else-if="column1.hideText" #default="{ row,rowIndex }">
          <HideableText :text="row[column1.prop]" :hide-positon="$getHidePosition(column1.hideText.idType, column1.hideText.country || row.documentType || row.entChinaFlag)" />
          <template v-if="!row[column1.prop]">{{ column1.hideText.default }}</template>
      </template>
    </ElTableColumn>
  </ElTable>
</template>

<script>
import { customerCalcs } from '@/utils/utils'
export default {
  name: 'SimpleTables',
  props: {
    // 是否显示序号
    index: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: [Boolean, String],
      default: false,
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionWidth: {
      type: [String, Number],
      default: '160px',
    },
    // table 列字段配置
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
    height: {
      type: [String, Number],
      default: 400,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    summaryMethod: {
      type: [Function, Array],
      default() {
        return []
      },
    },
  },
  data() {
    return {

    }
  },
  methods: {
    selectionChange(rows) {
      this.$emit('selectionChange', rows)
    },
    headerCellStyle() {
      return 'background-color: #f8f8f9;color: #515a6e;'
    },
    cellStyle() {
      return 'padding: 8px 0;'
    },
    doLayout() {
      this.$refs.table && this.$refs.table.doLayout()
    },
    toggleRowSelection(...rest) {
      this.$refs.table && this.$refs.table.toggleRowSelection(...rest)
    },
    summary(params) {
      const summaryMethod = this.summaryMethod
      if (Array.isArray(summaryMethod)) {
        return customerCalcs(summaryMethod, params)
      }
      return summaryMethod(params)
    },
  },
}
</script>

