<template>
  <ElFormItem
    :label="labelWidth === '0' ? '' : labelText(item.label)"
    :show-message="showMessage"
    :label-width="item.labelWidth ? item.labelWidth + 'px' : labelWidth + 'px'"
    :prop="ruleProp === '' ? (item.obj ? item.obj + '.' + item.prop : item.prop) : ruleProp"
    :rules="rulesList"
  >
    <ElInput
      v-if="item.type === 'password'"
      v-model="currentValue"
      type="password"
      :placeholder="`${$t('请输入')}${item.label}`"
      :style="{width: `${item.width ? item.width : width}px`}"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
    />
    <ElInput
      v-if="item.type === 'input'"
      v-model="currentValue"
      :placeholder="`${$t('请输入')}${item.label}`"
      :style="{width: `${item.width ? item.width : width}px`}"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
    >
      <span v-if="item.rightText" slot="suffix">
        {{ item.rightText }}
      </span>
    </ElInput>
    <ElInput
      v-else-if="item.type === 'textarea'"
      v-model="currentValue"
      type="textarea"
      :placeholder="`${$t('请输入')}${item.label}`"
      :style="{width: `${item.width ? item.width : width}px`}"
      :autosize="{ minRows: 2, maxRows: 4}"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
    />
    <ElRadioGroup v-else-if="item.type === 'radio'" v-model="currentValue" :style="{width: `${item.width ? item.width : width}px`}">
      <ElRadio
        v-for="i in item.list"
        :key="i[item.key ? item.key : 'id'] "
        :label="i[item.key ? item.key : 'id']"
        :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      >
        {{ i.name }}
      </ElRadio>
    </ElRadioGroup>
    <ElCheckboxGroup
      v-else-if="item.type === 'checkbox'"
      v-model="currentValue"
      :style="{width: item.width ? item.width + 'px' : '100%'}"
    >
      <ElCheckbox
        v-for="i in item.list"
        :key="i[item.key ? item.key : 'id'] "
        border
        style="min-width: 120px;margin: 10px; padding: 7px;"
        :label="i[item.key ? item.key : 'id']"
        :disabled="i.disabled ? i.disabled : false"
      >
        {{ i.name }}
      </ElCheckbox>
    </ElCheckboxGroup>
    <ElSelect
      v-else-if="item.type === 'select' && item.key"
      v-model="currentValue"
      :placeholder="`${$t('请选择')}${item.label}`"
      filterable
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      :value-key="item.key ? item.key : 'id'"
      :style="{width: `${item.width ? item.width : width}px`}"
    >
      <ElOption
        v-for="i in setList"
        :key="i[item.key ? 'id' : item.key]"
        :label="i[item.setLabel ? item.setLabel : 'name']"
        :value="i"
        :disabled="i.disabled"
      />
    </ElSelect>
    <ElSelect
      v-else-if="item.type === 'select'"
      v-model="currentValue"
      :placeholder="`${$t('请选择')}${item.label}`"
      filterable
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      :style="{width: `${item.width ? item.width : width}px`}"
    >
      <ElOption
        v-for="i in setList"
        :key="i[item.setProp || 'value']"
        :label="i[item.setLabel || 'name']"
        :value="i[item.setProp || 'value']"
        clearable
        :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      />
    </ElSelect>
    <ElDatePicker
      v-else-if="item.type === 'ymd'"
      v-model="currentValue"
      :placeholder="item.placeholder ? item.placeholder : $t('请选择日期')"
      :clearable="false"
      :style="{width: `${item.width ? item.width : width}px`}"
      type="date"
      :editable="false"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
    />
    <ElDatePicker
      v-else-if="item.type === 'ym'"
      v-model="currentValue"
      :placeholder="item.placeholder ? item.placeholder : $t('请选择月份')"
      :clearable="false"
      :style="{width: `${item.width ? item.width : width}px`}"
      type="month"
      :editable="false"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      format="yyyy-MM"
      value-format="yyyy-MM"
    />
    <ElDatePicker
      v-else-if="item.type === 'y'"
      v-model="currentValue"
      :placeholder="item.placeholder ? item.placeholder : $t('请选择月年份')"
      :clearable="false"
      :style="{width: `${item.width ? item.width : width}px`}"
      type="year"
      :editable="false"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      format="yyyy"
      value-format="yyyy"
    />
    <ElDatePicker
      v-else-if="item.type === 'ymd_2'"
      v-model="currentValue"
      :start-placeholder="$t('开始日期')"
      :end-placeholder="$t('截止日期')"
      :style="{width: `${item.width ? item.width : width}px`}"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      type="daterange"
      :editable="false"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
    />
    <ElDatePicker
      v-else-if="item.type === 'ymd_hms_2'"
      v-model="currentValue"
      :start-placeholder="$t('开始日期')"
      :end-placeholder="$t('截止日期')"
      :style="{width: `${item.width ? item.width : width}px`}"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      type="datetimerange"
      :editable="false"
      :default-time="['00:00:00', '23:59:59']"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
    />
    <ElCascader
      v-else-if="item.type === 'cascader'"
      v-model="currentValueArray"
      :placeholder="`${$t('请选择')}${item.label}`"
      :options="setList"
      :show-all-levels="false"
      filterable
      clearable
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      :props="setProps"
      :style="{width: `${item.width ? item.width : width}px`}"
    />
    <ElCascader
      v-else-if="item.type === 'address'"
      v-model="currentValue"
      :placeholder="`${$t('请选择地址')}`"
      :options="setList"
      filterable
      :clearable="false"
      :disabled="item.disabled ? item.disabled : (disabled ? disabled : false)"
      :props="setPropsAddress"
      :style="{width: `${item.width ? item.width : width}px`}"
    />
  </ElFormItem>
</template>
<script>
import { get } from '@/http/request'
export default {
  name: 'SeniorPop',
  props: {
    value: {
      type: [String, Boolean, Object, Array, Number, Date],
      default: '',
    },
    labelWidth: {
      type: [String, Number],
      default: '100',
    },
    width: {
      type: [String, Number],
      default: '210',
    },
    item: {
      type: Object,
      required: true,
    },
    ruleProp: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    rule: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: this.$t('今天'),
          onClick(picker) {
            picker.$emit('pick', new Date())
          },
        }, {
          text: this.$t('昨天'),
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        }, {
          text: this.$t('一周前'),
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        }],
      },
      daterangeOpt: {
        shortcuts: [{
          text: this.$t('今天'),
          onClick(picker) {
            picker.$emit('pick', new Date())
          },
        }, {
          text: this.$t('昨天'),
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        }, {
          text: this.$t('一周前'),
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        }],
      },
      setList: [],
      addressCode: '',
      setProps: {
        checkStrictly: true,
        value: 'id',
        children: 'children',
        label: 'name',
      },
      setPropsAddress: {
        value: 'code',
        children: 'children',
        label: 'name',
        lazy: true,
        checkStrictly: false,
        lazyLoad: (node, resolve) => {
          if (node.level == 0) {
            this.getAddresListAll(this.item, resolve)
            resolve()
          } else if (node.data.type == 3) {
            resolve()
          } else {
            get(this.item.url, { code: node.data.code }).then(res => {
              if (res.code === 0) {
                for (let i = res.data.length; i--;) {
                  res.data[i].leaf = res.data[i].type == 3 ? true : false
                }
                resolve(res.data)
              }
            })
          }
        },
      },
    }
  },
  computed: {
    rulesList() {
      if (this.item.rule === false) {
        return {
          trigger: ['blur', 'change'],
          validator: (rule, value, callback) => {
            callback()
          },
        }
      } else if (this.item.rule) {
        if (this.item.rule === 'number') {
          return [
            {
              required: true,
              trigger: ['blur', 'change'],
              validator: (rule, value, callback) => {
                if (value === null || value === '') {
                  callback(new Error(`${this.$t('请输入')}${this.item.label}`))
                // } else if (! /^(([^0][0-9]+|0)\.([0-9]{1,2}))$/.test(value)) {
                } else if (value == 0) {
                  callback()
                } else if (/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value) == false) {
                  callback(new Error(this.$t('请输入数字类型，最多保留两位小数')))
                } else {
                  callback()
                }
              },
            }]
        }
        return this.item.rule
      } else if (this.rule) {
        if (this.item.type === 'address') {
          return [
            {
              required: true,
              trigger: ['blur', 'change'],
              validator: (rule, value, callback) => {
                if (value === null || value === '' || value.length !== 3) {
                  callback(new Error(this.$t('请选择完整地址')))
                } else {
                  callback()
                }
              },
            }]
        }
        return [
          {
            required: true,
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (value === null || value === void 0 || value === '' || JSON.stringify(value) === '{}') {
                if (this.item.type == 'input') {
                  callback(new Error(`${this.$t('请输入')}${this.item.label}`))
                } else {
                  callback(new Error(`${this.$t('请选择')}${this.item.label}`))
                }
              } else {
                callback()
              }
            },
          }]
      }
      return {
        trigger: ['blur', 'change'],
        validator: (rule, value, callback) => {
          callback()
        },
      }

    },
    currentValue: {
      // 动态计算currentValue的值
      get() {
        return this.value // 将props中的value赋值给currentValue
      },
      set(val) {
        this.$emit('input', val) // 通过$emit触发父组件
      },
    },
    currentValueArray: {
      // 动态计算currentValue的值
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        if (this.value === '') {
          return []
        }
        let forDG =  (arr, id, last = []) => {
          for (let i = arr.length; i--;) {
            if (arr[i].id === id) {
              last.push(id)
              return { state: true, data: last, name: arr[i].name, id: arr[i].id }
            } else {
              let arrFirst = []
              arrFirst = Object.assign(arrFirst, last)
              arrFirst.push(arr[i].id)
              if (arr[i].children !== null && arr[i].children !== void 0 && arr[i].children.length !== 0) {
                let state = forDG(arr[i].children, id, arrFirst)
                if (state.state) {
                  return state
                }
              }
            }
          }
          return { state: false, data: [] }
        }
        let list = forDG(this.setList, this.value)
        if (list.state) {
          return list.data
        }
      },
      set(val) {
        if (val.length > 0) {
          this.$emit('input', val[val.length - 1])
        } else {
          this.$emit('input', '')
        }
      },
    },
  },
  created() {
    this.getListAjax(this.item)
  },
  methods: {
    getAddresListAll(item, resolve) {
      let list = this.value
      if (list.length === 3) {
        let forDG =  (arr, code, last = []) => {
          for (let i = arr.length; i--;) {
            if (arr[i].code === code) {
              last.push({ code: code, index: i })
              return { state: true, data: last, name: arr[i].name, id: arr[i].id }
            } else {
              let arrFirst = []
              arrFirst = Object.assign(arrFirst, last)
              arrFirst.push({ code: arr[i].code, index: i })
              if (arr[i].children !== null && arr[i].children !== void 0 && arr[i].children.length !== 0) {
                let state = forDG(arr[i].children, code, arrFirst)
                if (state.state) {
                  return state
                }
              }
            }
          }
          return { state: false, data: [] }
        }
        let children0 = []
        let children1 = []
        let children2 = []
        let chindrenFun = () => {
          if (children0.length !== 0 && children1.length !== 0 && children2.length !== 0) {
            let setIndex1 = forDG(children0, list[0])
            let setIndex2 = forDG(children1, list[1])
            children1[setIndex2.data[0].index].children = children2
            children0[setIndex1.data[0].index].children = children1
            this.setList = children0
          }
        }
        get(item.url, {}).then(res => {
          if (res.code === 0) {
            for (let i = res.data.length; i--;) {
              res.data[i].leaf = res.data[i].type == 3 ? true : false
            }
            children0 = item.getListProp(res)
            chindrenFun()
          }
        })
        get(item.url, { code: list[0] }).then(res => {
          if (res.code === 0) {
            for (let i = res.data.length; i--;) {
              res.data[i].leaf = res.data[i].type == 3 ? true : false
            }
            children1 = item.getListProp(res)
            chindrenFun()
          }
        })
        get(item.url, { code: list[1] }).then(res => {
          if (res.code === 0) {
            for (let i = res.data.length; i--;) {
              res.data[i].leaf = res.data[i].type == 3 ? true : false
            }
            children2 = res.data
            chindrenFun()
          }
        })
      } else {
        get(item.url, {}).then(res => {
          if (res.code === 0) {
            for (let i = res.data.length; i--;) {
              res.data[i].leaf = res.data[i].type == 3 ? true : false
            }
            resolve(item.getListProp(res))
          }
        })
      }
    },
    labelText(val) {
      if (val[val.length - 1] === ':' || val[val.length - 1] === ':') {
        return val
      } else {
        return val + ':'
      }
    },
    getListAjax(val) {
      if (val.url && val.type !== 'address') {
        if (this.item.props) {
          this.setProps = this.item.props
        }
        get(val.url, val.parms).then(res => {
          if (res.code === 0) {
            if (val.getListProp) {
              this.setList = val.getListProp(res)
            } else {
              this.setList = res.data
            }
          }
        })
      } else {
        this.setList = val.list
      }
    },
  },
}
</script>
