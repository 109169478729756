<template>
  <div style="display: inline;block">
    <ElDialog
      :title="$t('表头字段设置')"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="520px"
      :append-to-body="true"
      @close="close"
    >
      <div class="wrapper">
        <ElCheckbox
          v-model="titleChk.all"
          :indeterminate="titleChk.state"
          size="small"
          class="table_chk"
          border
          @change="changeAll"
        > {{$t('全部显示')}}
        </ElCheckbox>
        <ElCheckboxGroup v-model="titleChk.chk" size="small" @change="checkChange">
          <ElCheckbox
            v-for="(item, index) in title"
            :key="index"
            class="table_chk"
            :label="item"
            border
          >
            {{
              item.label
            }}
          </ElCheckbox>
        </ElCheckboxGroup>
      </div>
    </ElDialog>
    <el-tooltip
      slot="reference"
      effect="dark"
      :content="$t('表头字段设置')"
      placement="top"
    >
      <el-button
        style="padding: 8px 10px;font-size:14px;"
        icon="el-icon-setting"
        plain
        size="mini"
        @click="dialogVisible = true"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ShowFiled',
  props: {
    title: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      titleChk: {
        state: false,
        all: true,
        chk: [],
        chkKey: [],
      },
      dialogVisible: false,
    }
  },
  watch: {
    title() {
      this.titleChk.chk = this.title
      this.titleChk.all = true
    },
    'titleChk.chk'() {
      this.updateParentTable()
    },
  },
  mounted() {
    this.titleChk.chk = this.title
    this.updateParentTable()
  },
  methods: {
    changeAll(val) {
      this.titleChk.chk = val ? this.title : []
      // this.titleChk.state = false
    },
    checkChange(val) {
      let count = val.length
      this.titleChk.all = count === this.title.length
      // this.titleChk.state = count > 0 && count < this.title.length
    },
    updateParentTable() {
      let labelArr = this.titleChk.chk.map(item => item.label)
      this.$emit('updateColumn', labelArr)
    },
    close() {
      this.$emit('update:dialogVisible', false)
    },
  },
}
</script>

<style scoped>
.wrapper{
  height: 300px; margin: 0; padding: 0; overflow-x: hidden; overflow-y: auto; text-align: center;
}
.table_chk {
	display: block !important;
	width: 460px;
	margin-top: 10px;
	margin-right: 0 !important;
	margin-left: 0 !important;
	text-align: left;
}
</style>
