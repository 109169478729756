<template>
  <div class="text-content-word-ellipsis" :title="text">
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Number],
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.text-content-word-ellipsis{
  display: inline-block;
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
