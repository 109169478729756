import { $Info } from './tipUtil'
import i18n from '@/lang/index'
export const getTbHeight = ($vue, tableRef, pageState) => {
  let top = 200
  if ($vue.$refs[tableRef]) {
    try {
      top = $vue.$refs[tableRef].getBoundingClientRect().top
    } catch (_e) {
      const rect = $vue.$refs[tableRef].$el.getBoundingClientRect()
      if (rect) {
        top = rect.top
      }
    }

  }
  let tbHeight = document.documentElement.clientHeight - top - 25
  tbHeight = pageState ? tbHeight - 32 : tbHeight
  return tbHeight
}


export const deepClone = function (target) {
  // 定义一个变量
  let result
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = [] // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {}
      for (let i in target) {
        result[i] = deepClone(target[i])
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target
  }
  // 返回最终结果
  return result
}

export const freezeArr = function (arr) {
  return Object.freeze(arr)
}

// table合计, 分页合计
export const customerCalcs = (columnList, param) => {
  const { columns, data } = param
  let sums = []
  columns.forEach((column, index) => {
    if (columnList.includes(column.property)) {
      let count = ''
      data.forEach(item => {
        if (/^(-)?\d/.test(item[column.property])) {
          count = (count || 0) + Number(item[column.property])
        }
      })
      sums[index] = count === '' ? count : Number(count.toFixed(4))
    } else {
      sums[index] = ''
    }

  })
  sums[0] = i18n.t('合计')
  return sums
}

import NP from 'number-precision'
// table合计, 分页合计
export const customerCalc = (columnList, param) => {
  const { columns, data } = param
  let sums = []
  columns.forEach((column, index) => {
    if (columnList.includes(column.property)) {
      let count = ''
      data.forEach(item => {
        if (/^(-)?\d/.test(item[column.property])) {
          count = NP.plus(count || 0, item[column.property])
        }
      })
      sums[index] = count === '' ? count : count.toFixed(4)
    } else {
      sums[index] = ''
    }

  })
  sums[0] = [i18n.t('合计')]
  return sums
}

// table 合计行，数据来源于后端总计
export const customerTotalCalc = (columnList, param) => {
  const { columns, data } = param
  let sums = []
  columns.forEach((column, index) => {
    if (columnList.includes(column.property)) {
      sums[index] = data[column.property]
    } else {
      sums[index] = ''
    }

  })
  sums[0] = [i18n.t('合计')]
  return sums
}

/**
 * 数值 乘 100
 * @param {number} num
 * @returns {number}
 */
export const mulNum = (num) => {
  let value = num || 0
  return NP.times(value, 100)
}

/**
 * 数值 除 100
 * @param {number} num
 * @returns {number}
 */
export const divNum = (num) => {
  let value = num || 0
  return NP.divide(value, 100)
}

import { baseStorage } from './storage'

export const hasMenuAuth = (path) => {
  const menuTree = baseStorage.getItem('menu') || []
  for (let index = 0; index < menuTree.length; index++) {
    const items = menuTree[index].children || []
    for (let innerIndex = 0; innerIndex < items.length; innerIndex++) {
      if (items[innerIndex].path === path) {
        return items[innerIndex]
      }
    }
  }
  return false
}


import router from '@/router/router'
/**
 * 路由跳转公共方法
 * @param {object} link
 * @returns
 */
export const linkRouter = (link) => {
  const value = hasMenuAuth(link.path)
  if (value === false) {
    $Info.warning(i18n.t('暂无权限访问'))
    return
  }
  if (link.query) {
    router.push({
      path: `/${link.path}`,
      query: {
        ...link.query,
      },
    })
    return
  }
  router.push({
    name: link.path,
    params: {
      ...link.params,
    },
  })
}

export const debounce = function (fn, time) {
  let delay = time || 1000
  let timer
  return function deb() {
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }

    let callNow = !timer

    timer = setTimeout(() => {
      timer = null
    }, delay)

    if (callNow) fn.apply(this, args)
  }
}

/**
 * 树结构部门数据转换成 {id: name} 结构数据
 * @param {Array} dept
 * @returns {object}
 */
export const flagDeptObj = (dept, sep = '-') => {
  const stack = deepClone(dept)
  const obj = {}
  // 模拟栈，管理结点
  while (stack.length) {
    // 栈顶节点出栈
    const node = stack.shift()
    obj[node.id] = node.name
    const children = node.children || []
    if (children.length) {
      children.forEach(item => {
        item.name = `${node.name}${sep}${item.name}`
      })
      // 将候选顶点入栈，进行下一次循环
      stack.push(...node.children)
    }
  }
  return obj
}


/**
 * 数组数据转换成tree
 * @param {*} data
 * @returns
 */
export const array2Tree = (data, options = {}) => {
  const tmpMap = {}
  const tree = []
  const key = options.key || 'id'
  const parentKey = options.parentKey || 'pid'
  const childKey = options.childKey || 'children'

  data.forEach((item) => {
    tmpMap[item[key]] = item
  })

  data.forEach((item) => {
    if (tmpMap[item[parentKey]] && item[key] !== item[parentKey]) {
      if (!tmpMap[item[parentKey]][childKey]) tmpMap[item[parentKey]][childKey] = []
      tmpMap[item[parentKey]][childKey].push(item)
    } else {
      tree.push(item)
    }
  })

  return tree
}

/**
 * tree转换成数组
 * @returns
 */
export const tree2Array = (tree, options = {}) => {
  const list = []
  const stack = [...tree]
  while (stack.length) {
    const node = stack.shift()
    list.push({
      ...node,
      children: null,
    })
    if (node.children && node.children.length) {
      node.children.forEach(item => {
        item.pid = node.id
      })
      stack.push(...node.children)
    }
  }
  return list
}
/**
 * 处理部门数据，加上（已删除）
 * @param {array} deptList
 * @returns
 */
export const handleDept = (deptList) => {
  const list = tree2Array(deepClone(deptList))
  list.forEach(item => {
    if (item.manualDel === '1') {
      item.name = `${item.name}（${i18n.t('已删除')}）`
    }
  })

  return array2Tree(list)
}

// 脱敏数据解密
export function decryptDesensitizedData(data) {
  // Your decryption logic here
}

/**
  * 根据证件类型, 是否是中国, 选择隐藏位置
  * @param {number} idType 0:身份证号 1:手机号码 2:银行卡号
  * @param {number} country 0:中国居民身份证 1:越南身份证 99:其他类型
  * @returns {array} [start,end] [隐藏开始位置, 隐藏结束位置]
  */
export function getHidePosition(idType,country = 0) {
  // 初始化start和end变量
  let start, end;

  if (idType == 0) { // 身份证号
    if (country == 0) { // 中国居民身份证
      start = 6;
      end = 16;
    } else { // 越南身份证或其他证件类型
      start = 3;
      end = 6;
    }
  } else if (idType == 1) { // 手机号码
    if (country == 0) { // 中国手机号码
      start = 3;
      end = 7;
    } else { // 越南身份证或其他证件类型手机号码
      start = 3;
      end = 6;
    }
  } else if (idType == 2) { // 银行卡号
    if (country == 0) { // 中国银行卡号
      start = 6;
      end = -4; // 表示倒数第4位
    } else { // 越南身份证或其他证件类型银行账户
      start = 2;
      end = -2; // 表示倒数第2位
    }
  } else {
    throw new Error('Unsupported idType');
  }

  return [start, end];
}
