<template>
  <ElSelect
    v-model="id"
    :placeholder="$t('请选择币种')"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-option
      v-for="item of cnyTypeList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </ElSelect>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CnyTypeSelect',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  computed: {
    ...mapState({
      cnyTypeList: state => state.common.cnyTypeList,
    }),
    id: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
