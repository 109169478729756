<template>
  <div class="text-overflow" style="text-align:right;">
    {{ num }}
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    fixed: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    num() {
      if (this.value || this.value === 0) {
        if (this.fixed) {
          return this.$Rule.priceFormat3(this.value, this.fixed)
        }
        return this.$Rule.priceFormat(this.value)
      }
      return ''
    },
  },
}
</script>
