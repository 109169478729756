export const baseURL = ''

export const OSSURIPrefix = 'https://lapel-erp.oss-cn-hangzhou.aliyuncs.com/'

// 下载导出的excel*******载服务端本地文件，不支持oss文件下载
// {
//   filepath: '',
//   filename: '',
// }
export const downloadFileUrl1 = 'open/file/download'

// 下载文件oss
// 添加参数 name 用来自定义下载文件的名称，不带后缀（点号）则使用fileName的后缀
export const downloadFileUrl2 = '/hriceErp/open/file/download-file'


// 下载文件oss
// 添加参数 name 用来自定义下载文件的名称，不带后缀（点号）则使用fileName的后缀
// {
//   fileName: '',
//   preview:
// }
export const downloadFileUrl3 = '/hriceErp/open/file/OSSDownloadOrPreview'
