import axios from 'axios'
import { showToast } from './message'
import { responseHandle } from './httpHandle'
import i18n from "@/lang/index"

const http = axios.create({
  baseURL: '/hriceErp/',
  // timeout: 10000,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

//请求拦截
http.interceptors.request.use((config) => {
  // const timetamp = Date.now()
  // config.headers.timetamp = timetamp
  const lang = localStorage.getItem("lang") || 'zh-CN';
  const langMap = {
    'zh-CN': 'zh_CN',
    'vi': 'vi_VN'
  }

  config.headers["lang"] = langMap[lang]

  return config
})

// 响应拦截
http.interceptors.response.use(
  (res) => {
    // 文件流 直接过去
    if (res.request.responseType === 'blob') {
      return res
    }
    const data = res.data

    responseHandle(data)

    return data
  },
  (err) => {
    const response = err.response
    const { status } = response
    const errorMessage = response.data.error
      ? response.data.error
      : err.message
    switch (status) {
      case 400:
        showToast(errorMessage, i18n.t('错误'), 'error')
        break
      case 401:
        showToast(i18n.t('登录超时！'), i18n.t('警告'), 'warning')
        break
      case 404:
        showToast(i18n.t('接口不存在，请检查！'), i18n.t('错误'), 'error')
        break
      case 405:
        showToast(errorMessage, i18n.t('错误'), 'error')
        break
      case 413:
        showToast(i18n.t('文件过大'), i18n.t('错误'), 'error')
        break
      case 414:
        showToast(i18n.t('请求数据过多，长度超出url长度限制'), i18n.t('错误'), 'error')
        break
      case 422:
        showToast(errorMessage, i18n.t('错误'), 'error')
        break
      case 503:
        showToast(errorMessage, i18n.t('错误'), 'error')
        break
      case 504:
        showToast(i18n.t('服务器未启动！ 异常状态码:504'), i18n.t('错误'), 'error')
        break
      case 500:
        showToast(i18n.t('网络异常，请重试'), i18n.t('错误'), 'error')
        break
      default:
        showToast(errorMessage, i18n.t('错误'), 'error')
    }
    return Promise.reject(response)
  },
)

export default http
