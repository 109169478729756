/**
 * @author xueliang
 * @date 2021-10-09
 * @update
 * 数据验证工具库
 */
import i18n from '@/lang/index'

export const isEmpty = (val) => {
  return val === null || val === void 0 || val === ''
}

export const isUndefined = (val) => {
  return typeof val === 'undefined'
}

export const isNumber = (val) => {
  return typeof val === 'number'
}

export const isArray = (val) => {
  return Array.isArray(val)
}

export const isMobile = (val) => {
  return /^1[3456789]\d{9}$/.test(val)
}

export const isFixedPhone = (val) => {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(val)
}

// 座机xxx-xxx-xxxx
export const isFixedPhone1 = (val) => {
  return /^\d{3,4}-\d{3,4}-\d{3,4}$/.test(val)
}

export const isIdCard = (val) => {
  return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(val)
}

export const isExcel = (name) => {
  return /(\.xls|\.xlsx)$/.test((name || '').toLowerCase())
}

export const isImage = (name) => {
  return /(\.jpg|\.jpeg|\.png)$/.test((name || '').toLowerCase())
}

export const isOutLimit = (size, limit) => {
  return size > limit * 1024 * 1024
}

export const isOutTimeInteval = (end, start, limit) => {
  return new Date(end).getTime() - new Date(start).getTime() > limit * 1000 * 24 * 60 * 60
}
import { showToast } from '@/http/message'

/**
 * 验证文件是否修改
 * @param {File} file
 * @returns Promise
 */
export const fileChanged = (file) => {
  return new Promise((resolve, reject) => {
    file.slice(0, 1) // only the first byte
      .arrayBuffer() // try to read
      .then(resolve)
      .catch(() => {
        showToast(i18n.t('文件已经改动，不能上传，请重新选择'), i18n.t('提示'), 'warning')
        reject()
      })
  })
}


import { $Info } from '@/utils/tipUtil'

/**
 * 表单验证通用函数
 * @param { object } baseRef form表单的ref
 * @param {string[]} validPropArr 验证字段的数组
 * @param {Function} cb 回调函数
 */
export const validateProp = (baseRef, validPropArr, cb) => {
  if (validPropArr.length === 0) {
    cb()
    return
  }
  let count = 0
  let isValid = true // 是否验证通过
  baseRef.validateField(validPropArr, (err) => {
    count++
    if (err) {
      isValid = false
    }
    if (count === validPropArr.length && isValid) {
      if (isValid) {
        // 全部验证并且通过
        cb()
      } else {
        $Info.warning(i18n.t('请填写完整表单'))
      }
    }
  })
}
