/**
 *
 * @param {*} res 接口返回原始数据
 * @returns
 */
export const getExportName = (res) => {
  const { headers } = res
  const disposition = headers['content-disposition'].split(';')[1]
  const filename = disposition.split('=')[1]
  return decodeURIComponent(filename)
}

/**
 *
 * @param {*} blob blob数据
 * @param {*} filename 文件名
 */
export const saveFile = (data, filename) => {
  let blob = data
  if (/.xlsx$/.test(filename)) {
    blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  } else if (/.xls$/.test(filename)) {
    blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    })
  }

  if ('msSaveOrOpenBlob' in navigator) {
    // 兼容edge
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    /* 火狐谷歌的文件下载方式 */
    const a = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    a.href = href
    a.download = filename
    document.body.appendChild(a)
    const evt = new MouseEvent('click', {
      bubbles: false,
      cancelable: true,
      view: window,
    })
    a.dispatchEvent(evt)
    window.URL.revokeObjectURL(href)
    setTimeout(() => {
      document.body.removeChild(a)
    }, 100)
  }
}
import { downloadFileUrl3 } from './urls'

export const saveFileLink = (href, filename) => {
  const a = document.createElement('a')
  a.href = `${downloadFileUrl3}?fileName=${href}`
  a.target = '_blank'
  a.download = filename
  document.body.appendChild(a)
  const evt = new MouseEvent('click', {
    bubbles: false,
    cancelable: true,
    view: window,
  })
  a.dispatchEvent(evt)
  setTimeout(() => {
    document.body.removeChild(a)
  }, 100)
}
