<template>
  <div id="app">
    <RouterView />
  </div>
</template>
<script>
import { storage } from '@/utils/storage'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      hasDialog: false,
    }
  },
  computed: {
    ...mapState({
      visitedViews: state => state.tagView.visitedViews,
    }),
  },
  mounted() {
    window.RELEASE_ENV = process.env.VUE_APP_SENTRY
    window.addEventListener('error', (eventErr) => {
      const filename = eventErr.filename || ''
      if (/(.css|.js)$/.test(filename)) {
        if (this.hasDialog) {
          return
        }
        this.hasDialog = true
        this.$alert(this.$t('因版本更新，页面需重新载入以加载对应资源！'), this.$t('提示'), {
          confirmButtonText: this.$t('确定'),
          callback: () => {
            const pathname = window.location.pathname || ''
            // 1. 在登录页，直接刷新
            if (pathname.includes('login')) {
              window.location.reload(true)
              return
            }
            // 在系统页面
            const keepList = this.visitedViews
            if (keepList.find(item => pathname.includes(item.path))) {
              window.location.reload(true)
              return
            }

            storage.setItem('activeRouter', 'main_index')
            storage.setItem('menu_open', '')
            window.location.reload(true)
          },
        })
      }
      eventErr.preventDefault()
    }, true)
    window.addEventListener('unhandledrejection', (event) => {
      event.preventDefault()
      console.log(event.reason)
    }, true)
    document.title = this.$t('项目管理系统')
  },
}
</script>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  background: #F5F7FA;
  overflow-x: hidden;
}
::-webkit-input-placeholder,
::shadow div {
  display: none;
}
.el-table__header,
.el-table__body {
  width: 100% !important;
}
.el-table .cell{
  line-height: 18px;
}
.el-button--small {
  height: 32px;
}
.span-item {
  display: inline-block;
  width: 50px;
  height: 40px;
  margin-right: 10px;

  /* vertical-align: top; */
  margin-bottom: 10px;
  color: #6f7b86;
  font-size: 14px;
  line-height: 40px;
  text-align: right;
  cursor: pointer;
}
.el-notification__content {
  word-break: break-all;
}
.select-ellipsis .el-select-dropdown__item{
  max-width: 600px;
}
.erp-form {
  box-sizing: border-box;
  min-width: 600px;
  margin: 18px 20px;
  padding-bottom: 30px;
  background-color: #fff;
  border-bottom: 1px solid #eaeef8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.erp-form-title-text {
  height: 48px;
  color: #303133;
  font-size: 16px;
  font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    Arial, sans-serif;

  /* padding-left: 12px; */
  line-height: 48px;
}

.erp-form-submit {
  position: fixed;
  right: -10px;
  bottom: -18px;
  left: 64px;
  z-index: 9;

  /* width: 100%; */
  height: 56px;
  padding: 12px 24px;
  line-height: 56px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
}

.erp-see-defined {
  padding-bottom: 60px;
}

.erp-look-defined {
  position: relative;
  float: left;
  box-sizing: border-box;
  height: 33px;
  margin-right: 60px;
  margin-bottom: 15px;
  line-height: 33px;

  > span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100px;
    height: 33px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
  }

  > p {
    display: block;
    width: 210px;
    height: 33px;
    margin-left: 100px;
    padding-left: 15px;
    line-height: 33px;
    border-bottom: 1px solid #eaeef8;

    label {
      max-width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.erp-form-title {
  position: relative;
  width: 100%;
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
  zoom: 1;
}

.erp-form-title-show {
  position: absolute;
  top: 0;
  right: 20px;
  line-height: 48px;
}

.card-see {
  margin: 20px;
  // padding-bottom: 20px;
}

.el-message-box__content {
  max-height: 300px;
  overflow: auto;
}


.el-drawer__header > :first-child {
  outline: none;
}

.el-drawer__header span {
  font-weight: bold;
  font-size: 16px;
}

.wuxiao-red {
  color: rgba(217, 0, 27, 1) !important;
  .vxe-cell {
    color: rgba(217, 0, 27, 1) !important;
  }
}
.bold-red{
  .vxe-cell--label,
  .vxe-cell {
    color: rgba(217, 0, 27, 1) !important;
    font-weight: bold !important;
  }
}
.primary-color .vxe-cell{
  color: #1890ff !important;
}
.wuxiao {
  margin-left: 4px;
  padding: 0 6px;
  color: #fff;
  font-size: 12px;
  // display: inline-block;
  background: rgba(217, 0, 27, 1);
  border-radius: 10px;
}

.orange {
  color: #f59a23;
}
</style>
