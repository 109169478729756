<template>
  <div class="sh-card">
    <div class="sh-card-container">
      <header v-if="titleVisible" class="sh-card-header">
        <slot name="title">
          {{ title }}
        </slot>
        <div class="sh-header-action">
          <slot name="action" />
        </div>
      </header>
      <div class="sh-card-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.sh-card{
  padding: 0 20px 20px;
  box-sizing: border-box;
  .sh-card-container{
    border: 1px solid #f0f1f4;
    background-color: #fff;
    overflow: hidden;
    // box-shadow: 0 0 8px rgba(0, 0, 0, .08);
  }
  .sh-card-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 44px;
    padding: 0 20px;
    border-bottom: 1px solid #f0f1f4;
    box-sizing: border-box;
    overflow: hidden;
  }
  .sh-header-action{
    margin-left: auto;
  }
  .sh-card-body{
    padding: 20px;
  }
}

</style>
