import Vue from 'vue'
import ShTables from './components/table/ShTables'

import erpTab from './components/tab'
import ossUpload from './components/upload/oss_upload.vue'
import erpSearch from './components/form/search'
import erpForm from './components/form/form'
import erpSee from './components/form/see'
import ShHeader from '@/components/ShHeader'
import ShBottom from '@/components/ShBottom.vue'
import ShCard from '@/components/ShCard'
import ShNumber from '@/components/ShNumber'
import ExportTables from '@/components/ExportTables'
import percentInputNumber from './components/percentInputNumber'
import Tausendstel from '@/components/Tausendstel'
import TextContent from '@/components/TextContent'
import TextEllipsis from '@/components/TextEllipsis'
import SimpleTables from '@/components/table/SimpleTables.vue'
import SimpleTable from '@/components/table/SimpleTable.vue'
import ShPage from '@/components/ShPage'
import ReturnText from '@/components/ReturnText'
import ShContainer from '@/components/ShContainer.vue'
import CnyTypeSelect from '@/components/commons/CnyTypeSelect.vue'
import HideableText from '@/components/HideableText'


Vue.component('ErpTab', erpTab)
Vue.component('OssUpload', ossUpload)
Vue.component('ErpSearch', erpSearch)
Vue.component('ErpForm', erpForm)
Vue.component('ErpSee', erpSee)
Vue.component('PercentInputNumber', percentInputNumber)
Vue.component('ShTables', ShTables)
Vue.component('ShHeader', ShHeader)
Vue.component('ShBottom', ShBottom)
Vue.component('ShCard', ShCard)
Vue.component('ShNumber', ShNumber)
Vue.component('ExportTables', ExportTables)
Vue.component('Tausendstel', Tausendstel)
Vue.component('TextContent', TextContent)
Vue.component('TextEllipsis', TextEllipsis)
Vue.component('SimpleTables', SimpleTables)
Vue.component('SimpleTable', SimpleTable)
Vue.component('ShPage', ShPage)
Vue.component('ReturnText', ReturnText)
Vue.component('ShContainer', ShContainer)
Vue.component('CnyTypeSelect', CnyTypeSelect)
Vue.component('HideableText', HideableText)
