<template>
  <div class="text-content-word">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.text-content-word{
  word-break: break-all;
  line-height: 1.4;
  padding-top: 6px;
}
</style>
