var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simple-table-wrap"},[_c('VxeTable',_vm._b({ref:"table",attrs:{"data":_vm.data,"highlight-hover-row":"","tooltip-config":{
      enterDelay: 100,
      leaveDelay: 100,
      enterable: true
    },"row-config":_vm.rowConfig,"column-config":_vm.columnConfig,"empty-text":_vm.$t('暂无数据'),"min-height":"36px"},on:{"checkbox-change":_vm.selectionChange,"checkbox-all":_vm.selectionChange,"radio-change":_vm.radioChange,"cell-click":_vm.cellClick,"current-change":_vm.currentChange,"resizable-change":_vm.resizableChange}},'VxeTable',_vm.$attrs,false),[(_vm.index)?_c('VxeColumn',{key:"seqIndex",attrs:{"fixed":"left","type":"seq","title":_vm.$t('序号'),"align":"center","width":"60","footer-align":"left"}}):_vm._e(),(_vm.types)?_c('VxeColumn',{key:_vm.types,attrs:{"type":_vm.types,"fixed":"left","title":"","align":"center","width":"60"}}):_vm._e(),(_vm.showAction)?_c('VxeColumn',{key:"action",attrs:{"fixed":"left","title":_vm.$t('操作'),"width":_vm.actionWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
    var rowIndex = ref.rowIndex;
    var $rowIndex = ref.$rowIndex;
    var column = ref.column;
    var columnIndex = ref.columnIndex;
    var $columnIndex = ref.$columnIndex;
    var _columnIndex = ref._columnIndex;
return [_vm._t("action",null,{"row":row,"rowIndex":rowIndex,"column":column,"columnIndex":columnIndex})]}}],null,true)}):_vm._e(),_vm._t("default"),_vm._l((_vm.columns),function(column1){return _c('VxeColumn',_vm._b({key:column1.field,attrs:{"min-width":column1.minWidth||'100px'},scopedSlots:_vm._u([(_vm.$slots[column1.field])?{key:"default",fn:function(ref){
    var row = ref.row;
    var rowIndex = ref.rowIndex;
    var $rowIndex = ref.$rowIndex;
    var column = ref.column;
    var columnIndex = ref.columnIndex;
    var $columnIndex = ref.$columnIndex;
    var _columnIndex = ref._columnIndex;
return [_vm._t(column1.field,null,{"row":row,"rowIndex":rowIndex,"column":column,"columnIndex":columnIndex})]}}:(column1.hideText)?{key:"default",fn:function(ref){
    var row = ref.row;
    var rowIndex = ref.rowIndex;
return [_c('HideableText',{attrs:{"text":row[column1.field],"hide-positon":_vm.$getHidePosition(column1.hideText.idType, column1.hideText.country || row.documentType || row.entChinaFlag)}}),(!row[column1.field])?[_vm._v(_vm._s(column1.hideText.default))]:_vm._e()]}}:null],null,true)},'VxeColumn',column1,false))})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }