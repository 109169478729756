import 'core-js'
import './styles/var.scss'

import './styles/element-theme.scss'
import './fonts/icon/iconfont.css'
import './fonts/menuicon/iconfont.css'
import 'vxe-table/lib/style.css'

import 'nprogress/nprogress.css'

import Vue from 'vue'
import GmapVue from 'gmap-vue'
import ElementUI from 'element-ui'
import router from './router/router'
import store from './store/store'
import i18n from '@/lang'
import * as Sentry from "@sentry/vue";

import {getHidePosition} from '@/utils/utils'
import { decryptList } from '@/utils/crypto'

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCu3FbU88Mcr9l63geEGewgXRzKbvN5ZZE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})
import './router/permission'

Vue.use(ElementUI, { size: 'small' })

import './importVxeTable'
import './installComponent'
import './directives/auth'

import App from './App.vue'


import { $Info, publicRule } from './utils/tipUtil'

const getIEVersion = function getIEVersion() {
  let code = 99
  if (navigator.appName == 'Microsoft Internet Explorer') {
    const i = navigator.userAgent
    if (new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})').exec(i) !== null) {
      code = parseFloat(RegExp.$1)
    }
  }
  if (code <= 9) {
    // eslint-disable-next-line no-alert
    alert('为了保障你的账号安全和最佳浏览体验，该页面即将不再支持IE9及以下IE版本浏览器访问，建议你升级到IE浏览器最新版本，或使用Chrome等浏览器最新版本。')
  }
}

getIEVersion()


const tab = function tab(start, end) {
  const oDate1 = new Date(start)
  const oDate2 = new Date(end)
  return oDate2.valueOf() > oDate1.valueOf()
}
//十分大小比较
const shiFen = function shiFen(shi, fen) {
  //分割开始时间的时
  const kaiShi = shi.split(':')[0]
  //分割开始时间的分
  const kaiFen = shi.split(':')[1]
  //分割结束时间的时
  const jieShi = fen.split(':')[0]
  //分割结束时间的分
  const jieFen = fen.split(':')[1]
  //判断两者的时
  if (kaiShi < jieShi) {
    return true
  } else if (kaiShi == jieShi) {
    //如果等于的话就判断两者的分
    return kaiFen < jieFen
  }
  return false

}


Vue.prototype.$Rule = publicRule
Vue.prototype.$Info = $Info

Vue.prototype.shiFen = shiFen
Vue.prototype.tab = tab
Vue.prototype.$getHidePosition = getHidePosition
Vue.prototype.$decryptList = decryptList


Vue.prototype.$Loading = {
  service: '',
  show() {
    this.service = ElementUI.Loading.service({
      fullscreen: true,
      body: true,
      lock: true,
      background: 'rgba(255, 255, 255, 0.8)',
    })
  },
  hide() {
    this.service && this.service.close()
  },
}


Vue.config.productionTip = false
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://f7ef036e029a4920276a07a6c79d0215@o4507542181576704.ingest.us.sentry.io/4507564702433280",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.VUE_APP_SENTRY,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

  + (function setWidths() {
    const debounce = function debounce(func, wait = 0) {
      if (typeof func !== 'function') {
        throw new TypeError('need a function arguments')
      }
      let timeid = null
      let result

      return function dece() {
        let context = this
        const args = arguments

        if (timeid) {
          clearTimeout(timeid)
        }
        timeid = setTimeout(function st() {
          result = func.apply(context, args)
        }, wait)

        return result
      }
    }
    const b = document.documentElement.clientWidth
    const d = document.documentElement.clientHeight
    store.commit('setWidth', b)
    store.commit('setHeight', d)
    window.addEventListener('resize', debounce(() => {
      const b1 = document.documentElement.clientWidth
      const d1 = document.documentElement.clientHeight
      store.commit('setWidth', b1)
      store.commit('setHeight', d1)
    }, 300))
  })()
Number.prototype.originToFixed = Number.prototype.toFixed
Number.prototype.toFixed = function toFixed(d) {
  let s = this + ''
  // eslint-disable-next-line no-param-reassign
  if (!d) d = 0
  if (s.indexOf('.') == -1) s += '.'
  s += new Array(d + 1).join('0')
  if (new RegExp('^(-|\\+)?(\\d+(\\.\\d{0,' + (d + 1) + '})?)\\d*$').test(s)) {
    // eslint-disable-next-line no-redeclare
    s = '0' + RegExp.$2
    let pm = RegExp.$1
    let a = RegExp.$3.length
    let b = true
    if (a == d + 2) {
      a = s.match(/\d/g)
      if (parseInt(a[a.length - 1]) > 4) {
        for (let i = a.length - 2; i >= 0; i--) {
          a[i] = parseInt(a[i]) + 1
          // eslint-disable-next-line max-depth
          if (a[i] == 10) {
            a[i] = 0
            b = i != 1
          } else break
        }
      }
      s = a.join('').replace(new RegExp('(\\d+)(\\d{' + d + '})\\d$'), '$1.$2')

    }
    if (b) s = s.substr(1)
    return (pm + s).replace(/\.$/, '')
  } return this + ''
}
