<template>
  <ElDialog
    :title="$t('导出')"
    :modal-append-to-body="true"
    :append-to-body="true"
    top="100px"
    :width="width"
    :visible="visible"
    @close="close"
  >
    <slot>
      <ElButton
        :loading="loading"
        type="primary"
        @click="exportData(1)"
      >
        {{ $t('导出所有数据') }}
      </ElButton>
      &emsp;
      <ElButton
        :loading="loading"
        :disabled="selectIds.length==0"
        @click="exportData(2)"
      >
        {{ $t('导出表格所选数据') }}
      </ElButton>
    </slot>
    <slot name="extend" />
  </ElDialog>
</template>
<script>
import * as http from '@/http/request'

export default {
  name: 'ExportTables',
  props: {
    // 导出地址
    url: {
      type: String,
      default: '',
    },
    // 导出全部数据请求参数
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    // 导出选择数据参数
    selectIds: {
      type: [Array, String],
      default() {
        return []
      },
    },
    // 选择导出参数名
    selectName: {
      type: String,
      default: 'idList',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    // 导出数据是否为文件流
    stream: {
      type: Boolean,
      default: true,
    },
    // 导出请求方法
    method: {
      type: String,
      default: 'downloadPost',
    },
    width: {
      type: String,
      default: '800px',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    exportData(flag) {
      // 1 全部数据    2.选择数据
      let req = {
        ...this.data,
      }
      if (flag === 2) {
        req[this.selectName] = this.selectIds
      }
      this.loading = true
      // 文件流下载
      if (this.stream) {
        http[this.method](this.url, req).then(({ code }) => {
          this.loading = false
          if (code === 0) {
            this.close()
          }
        }).catch(() => {
          this.loading = false
        })
        return
      }
      // 文件路径
      http.post(this.url, req).then(({ code, data }) => {
        if (code === 0) {
          const { filename } = data
          http.downloadGet('open/file/download', data, {}, filename)
          this.close()
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    close() {
      this.$emit('update:visible', false)
    },
  },
}
</script>
