import { storage } from '@/utils/storage'
import router from '@/router/router'

export default {
  namespaced: true,
  state: () => {
    return {
      visitedViews: storage.getItem('visitedViews') || [],
      activeRouter: storage.getItem('activeRouter') || '',
    }
  },
  mutations: {
    // 设置活动路由
    setActiveRouter: (state, path) => {
      state.activeRouter = path
      storage.setItem('activeRouter', path)
    },
    addVisitedView: (state, view) => {
      state.activeRouter = view.path
      storage.setItem('activeRouter', view.path)
      if (state.visitedViews.some(v => v.path === view.path)) {
        return
      }
      state.visitedViews.push(Object.freeze({
        title: view.meta.title,
        path: view.path,
        name: view.name,
      }))
      storage.setItem('visitedViews', state.visitedViews)

    },
    delVisitedView: (state, view) => {
      const index = state.visitedViews.findIndex(item => item.path === view.path)
      state.visitedViews.splice(index, 1)
      if (state.activeRouter === view.path) {
        if (state.visitedViews[index]) {
          router.push(state.visitedViews[index].path)
          state.activeRouter = state.visitedViews[index].path
        } else if (state.visitedViews[index - 1]) {
          router.push(state.visitedViews[index - 1].path)
          state.activeRouter = state.visitedViews[index - 1].path
        } else {
          router.push('/main_index')
          state.activeRouter = ''
        }
        storage.setItem('activeRouter', state.activeRouter)
      }

      storage.setItem('visitedViews', state.visitedViews)
    },
    delOthersVisitedViews: (state, view) => {
      state.visitedViews = state.visitedViews.filter(v => v.path === view.path)
      storage.setItem('visitedViews', state.visitedViews)
    },
    delAllVisitedViews: state => {
      state.visitedViews = []
      router.push('/main_index')
      storage.setItem('visitedViews', state.visitedViews)
    },
  },
}
