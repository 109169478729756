var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ElTable',_vm._b({ref:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.data,"stripe":"","size":"small","max-height":_vm.height,"header-cell-style":_vm.headerCellStyle,"cell-style":_vm.cellStyle,"show-summary":_vm.showSummary,"summary-method":_vm.summary},on:{"selection-change":_vm.selectionChange}},'ElTable',_vm.$attrs,false),[(_vm.index)?_c('ElTableColumn',{attrs:{"type":"index","fixed":_vm.fixed,"label":_vm.$t('序号'),"align":"center","width":"50px"}}):_vm._e(),(_vm.selection)?_c('ElTableColumn',{attrs:{"type":"selection","align":"center","fixed":_vm.fixed,"width":"46px"}}):_vm._e(),(_vm.showAction)?_c('ElTableColumn',{attrs:{"type":"action","fixed":_vm.fixed,"align":"center","width":_vm.actionWidth},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [_vm._t("action",null,{"row":row,"index":$index,"column":column})]}}],null,true)}):_vm._e(),_vm._t("default"),_vm._l((_vm.columns),function(column1){return _c('ElTableColumn',_vm._b({key:column1.prop,attrs:{"prop":column1.prop,"min-width":"120px"},scopedSlots:_vm._u([(_vm.$slots[column1.field])?{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [_vm._t(column.field,null,{"row":row,"index":$index,"column":column})]}}:(column1.hideText)?{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('HideableText',{attrs:{"text":row[column1.prop],"hide-positon":_vm.$getHidePosition(column1.hideText.idType, column1.hideText.country || row.documentType || row.entChinaFlag)}}),(!row[column1.prop])?[_vm._v(_vm._s(column1.hideText.default))]:_vm._e()]}}:null],null,true)},'ElTableColumn',column1,false))})],2)}
var staticRenderFns = []

export { render, staticRenderFns }