<template>
  <div class="numberRange" :class="{'is-fouce': isFouceClass}">
    <ElInputNumber
      :value="value[0]"
      :precision="2"
      :min="-9999999999.99"
      :max="9999999999.99"
      controls-position="right"
      @change="handleChange1"
      @focus="onFocus"
      @blur="onBlur"
    />
    <span style="line-height:30px;">-</span>
    <ElInputNumber
      :value="value[1]"
      :precision="2"
      :min="-9999999999.99"
      :max="9999999999.99"
      controls-position="right"
      @change="handleChange2"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  name: 'NumberRange',
  props: {
    value: {
      type: [Array, String, Number],
      default() {
        return []
      },
    },
  },
  data() {
    return {
      isFouce: false,
    }
  },
  computed: {
    isFouceClass() {
      const isFouce = this.isFouce
      if (isFouce) {
        return true
      }
      const value = this.value
      if (Array.isArray(value)) {
        return value.length && (value[0] || value[0] === 0 || value[1] || value[1] === 0)
      }
      return value || value === 0
    },
  },
  methods: {
    onFocus() {
      this.isFouce = true
    },
    onBlur() {
      this.isFouce = false
    },
    handleChange1(value) {
      const calValue = [...this.value]
      calValue[0] = value
      this.$emit('input', calValue)
    },
    handleChange2(value) {
      const calValue = [...this.value]
      calValue[1] = value
      this.$emit('input', calValue)
    },
  },
}
</script>

<style lang="scss">
.numberRange{
  display: flex;
  border: 1px solid #DCDFE6;
  .el-input-number__decrease{
    display: none;
  }
  .el-input-number__increase{
    display: none;
  }
  .el-input-number.is-controls-right .el-input__inner{
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border: none;
  }
}
</style>
