/**
 * 验证字符串是否符合正则
 * @param {RegExp} regExp 正则表达式
 * @param {string} str 验证字符串
 * @returns
 */
export const regExpMatch = (regExp, str) => {
  return regExp.test((str || '').toLowerCase())
}

import NP from 'number-precision'
/**
 * 单个字段求和
 * @param {Array} data 数据
 * @param {String} prop 求和字段属性
 * @param {Number} cnyType 币种
 * @param {Number} fixed 保留小数位
 * @returns {number | string}
 */
export const summation = (data, prop, cnyType, fixed) => {
  if (cnyType) {
    let count = ''
    data.forEach(item => {
      const num = item[prop]
      if (num || num === 0) {
        count = NP.plus(count || 0, num) // (count || 0) + Number(num)
      }
    })
    if (count === '') {
      return ''
    }
    if (fixed) {
      return count.toFixed(fixed)
    }
    return Number(count.toFixed(2))
  }
  return '-'
}

/**
 * 临时id生成器
 */
export const genId = (() => {
  let id = 0
  return () => {
    id++
    return 'T' + id
  }
})()
