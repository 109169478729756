<!--
 * @Author: 陶泓
 * @Date: 2021-07-20 13:36:51
 * @LastEditors: 陶泓
 * @LastEditTime: 2021-07-20 14:19:06
 * @Description: 带百分号的数字输入框
-->
<script>
import { InputNumber } from 'element-ui'
InputNumber.props.controlsPosition.default = 'right'
export default {
  name: 'PercentInputNumber',
  extends: InputNumber,
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-number__increase,
.el-input-number__decrease {
  background: transparent;
  border: none;
  border-top: none !important;
  border-left: none !important;
  border-bottom: none !important;
  right: 32px !important;
}
::v-deep .el-input__inner {
  padding-right: 65px !important;
}
::v-deep .el-input {
  &::after {
    content: "%";
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    background: #f6f7fb;
    right: 1px;
    top: 1px;
    text-align: center;
  }
}
</style>
