import { storage, baseStorage } from '@/utils/storage'
import { deepClone } from '@/utils/utils'
import router from '@/router/router'
const add = function add({ path, name, component, title, level, authority }) {
  router.addRoute("layout",{
    path:  `/${path}`,
    component: level==2? () => import(`@/views/${component}`): () => () => import('@/views/main/index.vue'),
    name: path, // 命名路由
    meta: {
      title: title,
      authority: authority,
    },
  })
}
const findQuestions = function findQuestions(tree) {
  const list = []
  // 模拟栈，管理结点
  const stack = tree
  while (stack.length) {
    // 栈顶节点出栈
    const node = stack.shift()
    list.push({
      ...node,
      children: null,
    })
    add({
      ...node,
      children: null,
    })
    if (node.children && node.children.length) {
      // 将候选顶点入栈，进行下一次循环
      stack.push(...node.children)
    }
  }

  return list
}
const common = {
  state: () => ({
    menu: [], // 菜单树
    perms: [], // 权限list
    routerParmsNow: {}, // 当前路由信息快照
    refresh: false, // 页面是否刷新，false 为刷新
    collapse: storage.getItem('collapse') || false, // 菜单栏是否折叠
  }),
  getters: {
    menuGetter(state){
      return state.menu
    }
  },
  mutations: {
    // 同步 // 辅助函数 mapMutations
    setMenu(state, payload) {
      baseStorage.setItem('menu', payload || [])
      state.menu = payload || []
      state.refresh = true
    },
    setRouterParmsNow(state,payload){
      state.routerParmsNow = payload
    },
    setCollapse(state, payload) {
      state.collapse = payload
      storage.setItem('collapse', payload)
    }
  },
  actions: {
    addRoute({commit}, payload){
      return new Promise((resolve) => {
        findQuestions(deepClone(payload))
        router.addRoute({
          path: '*',
          name: '404',
          component: () => import('@/views/warning/404.vue') // '/index',
        })
        commit('setMenu', Object.freeze(payload))
        resolve()
      })
    }
  },
}

export default common
