<template>
  <ElForm
    :ref="refName"
    :inline="true"
    :model="seniorValue.data"
    :label-position="labelPosition"
    :size="size"
    class="erp-form-show"
  >
    <ShHeader :title="type === 'add'?$t('添加'):$t('编辑')" :header-prop="headerProp" @back="close" />
    <div
      v-for="(item_big, index_big) in seniorValue.title"
      :key="index_big"
      class="erp-form"
    >
      <ElFormItem class="erp-form-title">
        <div class="erp-form-title-text">
          {{ item_big.name }}
        </div>
        <div v-if="item_big.show !== undefined" class="erp-form-title-show" @click="item_big.show = !item_big.show">
          <i v-show="item_big.show" class="el-icon-minus" />
          <i v-show="!item_big.show" class="el-icon-plus" />
        </div>
      </ElFormItem>
      <span
        v-for="(item, index) in item_big.list"
        :key="index"
        :style="item_big.show === undefined ? '' : item_big.show ? '' : 'display: none'"
      >
        <SeniorPop
          v-if="item.obj"
          v-model="seniorValue.data[item.obj][item.prop]"
          class="erp-form-body"
          :rule="rule"
          :disabled="state"
          :item="item"
          :width="width"
          :label-width="labelWidth"
        />
        <SeniorPop
          v-else
          v-model="seniorValue.data[item.prop]"
          class="erp-form-body"
          :rule="rule"
          :disabled="state"
          :item="item"
          :width="width"
          :label-width="labelWidth"
        />
      </span>
    </div>
    <slot :value="value" />
    <ElFormItem class="erp-form-submit">
      <ElButton
        type="primary"
        plain
        style="width: 90px;"
        @click="submit()"
      >
        {{ $t('提交') }}
      </ElButton>
      <ElButton type="" style="width: 90px;" @click="rest()">
        {{ $t('重置') }}
      </ElButton>
      <ElButton type="" style="width: 90px;" @click="close()">
        {{ $t('关闭') }}
      </ElButton>
    </ElFormItem>
  </ElForm>
</template>
<script>
import SeniorPop from './pop/senior_pop.vue'
import i18n from '@/lang'
export default {
  name: 'ErpForm',
  components: {
    //组成局部组件
    SeniorPop,
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },
    labelWidth: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 260,
    },
    name: {
      type: String,
      default: i18n.t('我的表单标题'),
    },
    labelPosition: {
      type: String,
      default: 'top',
    },
    refName: {
      type: String,
      default: 'ErpSeniorForm',
    },
    size: {
      type: String,
      default: 'small',
    },
    state: {
      type: Boolean,
      default: false,
    },
    rule: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'edit',
    },
    headerProp: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      restList: {},
    }
  },
  computed: {
    seniorValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    getValue() {
      return this
    },
  },
  mounted() {
    // el已经挂载
    this.restList = Object.assign({}, this.value.data)
  },
  methods: {
    // 函数
    submit() {
      // this.$refs.ErpSearchForm.validate((valid) => {if (valid) {}})
      // this.$parent.search()
      this.$refs[this.refName].validate((state, val) => {
        if (state) {
          this.$parent.form_submit()
          // this.$Info.success('验证成功！')
        } else {
          this.$Info.info(this.$t('请完整填写表单内容！'))
        }
      })
    },
    close() {
      this.$parent.close()
    },
    rest() {
      this.seniorValue.data = Object.assign({}, this.restList)
      this.$refs[this.refName].resetFields()
    },
  },
}
</script>
