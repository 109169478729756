import router from './router'
import store from '../store/store'
import { storage, baseStorage } from '@/utils/storage'
import NProgress from 'nprogress'

router.beforeEach((to, from, next) => {
  NProgress.start()
  let listMeta = to.meta
  if (!listMeta.authority) {
    listMeta.authority = {}
  }
  store.commit('setRouterParmsNow', listMeta)
  if (to.path === '/login') {
    baseStorage.removeItem('userInfo')
    storage.clear()
    next()
  } else if (!store.state.user.userInfo.realName) {
    next('/login')
  } else if (to.path === '/') {
    next('/main_index')
  } else if (to.name == '404') {
    next()
  } else if (store.state.menu.refresh === false) {
    let menu = baseStorage.getItem('menu') || []
    store.dispatch('addRoute', menu).then(() => {
      next({ ...to }) // hack方法 确保addRoutes已完成
    })
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0)
})
