<template>
  <ElFormItem
    :label="labelText(item.label)"
    :label-width="labelWidth + 'px'"
    :prop="item.prop"
  >
    <div v-if="item.type === 'file'" class="senior_pop_look" :style="{width: `${item.width ? item.width : width}px`}">
      <ElLink type="primary" :underline="false" :href="`/hriceErp/${currentValue[item.prop]}`">
        {{ $t('下载文件') }}
      </ElLink>
    </div>
    <div v-else class="senior_pop_look" :style="{width: `${item.width ? item.width : width}px`}">
      {{ filerText(currentValue, item.prop, item.filer) }}
    </div>
  </ElFormItem>
</template>
<script>
export default {
  name: 'SeniorPop',
  props: {
    value: {
      type: [String, Boolean, Object, Array, Number, Date],
      default: '',
    },
    labelWidth: {
      type: [String, Number],
      default: '100',
    },
    filter: {
      type: [Array, Object],
      default: () => {
        return {}
      },
    },
    width: {
      type: [String, Number],
      default: '210',
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // picture_data: [],
    }
  },
  computed: {
    currentValue: {
      // 动态计算currentValue的值
      get() {
        return this.value // 将props中的value赋值给currentValue
      },
      set(val) {
        this.$emit('input', val) // 通过$emit触发父组件
      },
    },
  },
  methods: {
    filerText(val, prop, filer) {
      if (this.filter[prop] === void 0) {
        if (filer !== void 0) {
          if (this.$Rule[filer]) {
            return this.$Rule[filer](val[prop])
          }
          return this.filter[filer](val, prop, this.$Rule)
        }
        return val[prop]
      } else {
        return this.filter[prop](val, prop, this.$Rule)
      }
    },
    labelText(val) {
      if (val) {
        if (val[val.length - 1] === ':' || val[val.length - 1] === ':') {
          return val
        } else {
          return val + ':'
        }
      } else {
        return ''
      }
    },
  },
}
</script>
