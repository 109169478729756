import Vue from 'vue'
import VueI18n from 'vue-i18n'
import viLocale from 'element-ui/lib/locale/lang/vi'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import vi from './vi.json'//越南语
import zh from './zh.json'//中文
// element-ui 组件国际化
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n) // 全局注册国际化包
// 准备翻译的语言环境信息
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh-CN', // 通过this.$i18n.locale的值实现语言切换
  messages: {
    'zh-CN': { ...zh, ...zhLocale },
    'vi': { ...vi, ...viLocale },
  },
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
