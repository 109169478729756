<template>
  <div class="sh-table-pagination">
    <ElPagination
      :current-page="pageNo"
      :page-sizes="sizes"
      :page-size="pageSize"
      :layout="layout"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    pageNo: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: [String, Number],
      default: 50,
    },
    total: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper',
    },
    sizes: {
      type: Array,
      default() {
        return [10, 50, 100, 200]
      },
    },
  },
  data() {
    return {
      pageThrottle: false,
    }
  },
  methods: {
    sizeChange(size) {
      this.pageSizeSearch(1, size)
    },
    currentChange(page) {
      this.pageSizeSearch(page, this.pageSize)
    },
    pageSizeSearch(page, pageSize) {
      if (this.pageThrottle === true) {
        return
      }
      this.pageThrottle = true
      this.$emit('pageSizeSearch', page, pageSize)
      this.pageThrottle = false
    },
  },
}
</script>

<style lang="scss" scoped>
  .sh-table-pagination {
    padding: 10px 0;
    text-align: left;
  }
</style>
