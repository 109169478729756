<template>
  <div class="erp-tab">
    <ElTabs
      ref="tabs"
      :value="activeIndex"
      style="padding: 0px 10px;"
      @tab-click="onTabClick"
    >
      <ElTabPane
        v-for="(item, index) in tabs"
        :key="index"
        :label="`${item.label}${item.num !== undefined ? `(${item.num})` : ''}`"
        :name="item.name"
      />
    </ElTabs>
  </div>
</template>
<script>
export default {
  name: 'ErpTab',
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      },
    },
    activeIndex: {
      type: String,
      default: '0',
    },
  },
  watch: {
    tabs:{
      handler() {
        this.resetTabActivePosition(this.$refs.tabs.$el)
      },
      immediate: false,
      deep: true
    }
  },
  methods: {
    onTabClick(val) {
      if (val.name !== this.activeIndex) {
        this.$emit('update:activeIndex', val.name)
        this.$emit('onTabClick', val.name)
      }
    },
    resetTabActivePosition($el) {
        this.$nextTick(() => {
            const activeEl = $el.querySelector('.el-tabs__item.is-active');            
            if(!activeEl){
              return
            }
            const lineEl = $el.querySelector('.el-tabs__active-bar');
            const style =  window.getComputedStyle(activeEl);
            const pl = style.paddingLeft.match(/\d+/)[0] * 1;
            const pr = style.paddingRight.match(/\d+/)[0] * 1;
            const w = style.width.match(/\d+/)[0] * 1;
            lineEl.style.transform = 'translateX(' + (activeEl.offsetLeft + pl) + 'px)';
            lineEl.style.width = (w - pl - pr)+'px';
        })
    }

  },
}
</script>
<style lang="scss">
.erp-tab {
  margin-left: 10px;
  margin-right: 10px;
	line-height: 40px;
	background-color: #ffffff;
  .el-tabs__header{
    margin: 0;
  }
  .el-tabs__item {
    color: #222222 !important;
  }
}
</style>
