<template>
    <div v-if="text">
        <button class="view-wrap" @click="toggleTextVisibility">
            <i class="el-icon-view"></i>
            <i v-show="!showText" class="el-icon-minus"></i>
        </button>
        <span>{{ maskedText }}</span>
    </div>
</template>
  
<script>
export default {
    name: 'HideableText',
    props: {
        text: {
            validator: function (value) {
                return typeof value === 'string' || typeof value === 'number' || value === null;
            },
            required: true,
            default: ''
        },
        hidePositon: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            showText: false
        };
    },
    computed: {
        maskedText() {
            if (this.showText) {
                return this.text;
            } else {
                const start = this.hidePositon[0] || 0
                let end = this.hidePositon[1] || this.text.length
                if (end < 0) {
                    end = this.text.length + end
                }
                const startText = this.text.slice(0, start);
                const endText = this.text.slice(end);
                let masked = ''
                if(end - start > 0) {
                    masked = '*'.repeat(end - start);
                    return `${startText}${masked}${endText}`;
                } else {
                    return '*'.repeat(this.text.length);
                }
            }
        }
    },
    methods: {
        toggleTextVisibility() {
            this.showText = !this.showText;
        }
    }
};
</script>
  
<style lang="scss" scoped>
.view-wrap {
    margin-right: 5px;
    position: relative;
    cursor: pointer;

    .el-icon-minus {
        position: absolute;
        text-shadow: 0.5px 0.5px 0 #ffffff, -0.5px -0.5px 0 #ffffff, 0.5px -0.5px 0 #ffffff, -0.5px 0.5px 0 #ffffff, 0.5px 0.5px 0 #ffffff, -0.5px -0.5px 0 #ffffff, 0.5px -0.5px 0 #ffffff, -0.5px 0.5px 0 #ffffff;
        transform: scale(1.4) rotate(135deg);
        top: 2px;
        left: 0;
    }
}
</style>
  