import Vue from 'vue'

import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      meta: {
        title: 'login',
        authority: [],
      },
      name: 'login',
      component: () => import('@/views/login/login.vue'),
    },
    {
      path: '/',
      name: 'layout',
      component: () => import('@/views/main/index.vue'),
      children: [
        {
          path: '/export/center',
          title: 'export_center',
          name: 'export/center',
          meta: {
            title: '导出中心',
          },
          component: () => import('@/views/main/personal/export_center.vue'),
        },
        {
          path: '/personal/personal',
          title: 'personal_personal',
          name: 'personal/personal',
          meta: {
            title: '个人中心',
          },
          component: () => import('@/views/main/personal/personal.vue'),
        },
      ],
    },
    {
      path: '/main_index',
      title: 'main_index',
      component: () => import('@/views/main/index.vue'),
    },

  ],
})
export default router
