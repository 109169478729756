<template>
  <span class="return-text">{{$t('退')}}</span>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.return-text {
	display: inline-block;
	line-height: 1;
	border-radius: 50%;
	border: 1px solid #111;
	padding: 2px;
	font-size: 13px;
}
</style>
