import Vue from 'vue'
import store from '@/store/store'
Vue.directive('auth', {
  inserted(el, binding) {
    const routerParmsNow = store.state.menu.routerParmsNow || {}
    const authority = routerParmsNow.authority || {}
    const table = authority.table || []
    const operation = authority.operation || []
    const list = [...table, ...operation]
    if (!list.find(item => item.prop == binding.value)) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  },
})
