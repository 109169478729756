import { baseStorage } from '@/utils/storage'

export default {
  state: () => {
    return {
      userInfo: baseStorage.getItem('userInfo') || {},
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      baseStorage.setItem('userInfo', payload || {})
      state.userInfo = Object.freeze(payload || {})
    },
  },
}
