<template>
  <ElForm
    :ref="refName"
    :inline="true"
    :model="seniorValue.data"
    :label-position="labelPosition"
    :size="size"
    class="er-form-show"
  >
    <ShHeader :title="$t('查看')" @back="close" />
    <div v-for="(item_big, index_big) in seniorValue.title" :key="index_big" class="erp-form">
      <ElFormItem class="erp-form-title">
        <div class="erp-form-title-text">
          {{ item_big.name }}
        </div>
      </ElFormItem>
      <SeePop
        v-for="(item, index) in item_big.list"
        :key="index"
        v-model="seniorValue.data"
        class="erp-see-body"
        :filter="filter"
        :item="item"
        :label-width="labelWidth"
      />
    </div>
    <slot :value="value" />
    <ElFormItem class="erp-form-submit">
      <ElButton
        type=""
        :disabled="false"
        style="width: 90px"
        @click="close()"
      > {{$t('关闭')}}
      </ElButton>
    </ElFormItem>
  </ElForm>
</template>
<script>
import SeePop from './pop/see_pop.vue'
import ShHeader from '@/components/ShHeader'
export default {
  components: {
    //组成局部组件
    SeePop,
    ShHeader,
  },
  props: {
    value: {
      type: [Array, Object],
      required: true,
      default: () => {
        return {}
      },
    },
    labelWidth: {
      type: Number,
      default: 110,
    },
    width: {
      type: Number,
      default: 380,
    },
    labelPosition: {
      type: String,
      default: 'left',
    },
    filter: {
      type: [Array, Object],
      default: () => {
        return {}
      },
    },
    refName: {
      type: String,
      default: 'ErpSeeForm',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  data() {
    return {
      restList: {},
      header: this.$store.state.menu.routerParmsNow.title,
      openSelect: true,
    }
  },
  computed: {
    seniorValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    // el已经挂载
    this.restList = Object.assign({}, this.value.data)
  },
  methods: {
    // 函数
    close() {
      this.$parent.close()
    },
    rest() {
      this.seniorValue.data = Object.assign({}, this.restList)
      this.$refs[this.refName].resetFields()
    },
    labelText(val) {
      if (val[val.length - 1] === ':' || val[val.length - 1] === ':') {
        return val
      } else {
        return val + ':'
      }
    },
  },
}
</script>
<style lang="scss">
.erp-see-body {
	box-sizing: border-box;
	padding: 0 20px;
}

@media only screen and (max-width: 1300px) and (min-width: 1000px) {
	.erp-see-body {
		width: calc(50% - 10px);
	}
}

@media only screen and (min-width: 1300px) {
	.erp-see-body {
		width: calc(33% - 10px);
	}
}
</style>
