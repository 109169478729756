import $http from './base'
import { downloadHandle } from './httpHandle.js'
import { OSSURIPrefix, downloadFileUrl1 } from './urls'
import i18n from '@/lang/index'
const qs = require('qs')


const transformNull = (data) => {
  if (Array.isArray(data)) {
    return data
  }
  let obj = {}
  Object.keys(data).forEach(key => {
    if (data[key] !== null) {
      obj[key] = data[key]
    }
  })
  return obj
}

/**
 * 提交阶段去除前后空格
 * 1. 只有对象时，才需要做这个转换，其他情况不需要
 */
export const transformSpace = (data) => {
  if (!data) {
    return data
  }
  if (Object.prototype.toString.call(data) === '[object Object]') {
    const obj = {}
    Object.keys(data).forEach(key => {
      if (data[key] && Object.prototype.toString.call(data[key]) === '[object String]') {
        obj[key] = data[key].trim()
      } else {
        obj[key] = data[key]
      }
    })
    return obj
  }
  return data
}

/**
 * config 中可以配置上传下载进度函数，取消请求等配置
 */

/**
 * GET 请求
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const get = (url, params = {}, config = {}) => {
  const { headers = {}, ...configRest } = config
  return $http.get(url, {
    params: transformSpace(params),
    ...configRest,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })
}

/**
 * POST json 求情
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const post = (url, data = {}, config = {}) => {
  const { headers = {}, ...configRest } = config
  return $http.post(url, JSON.stringify(transformSpace(data)), {
    ...configRest,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })
}
/**
 * PUT json 求情
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const put = (url, data = {}, config = {}) => {
  const { headers = {}, ...configRest } = config
  return $http.put(url, JSON.stringify(transformSpace(data)), {
    ...configRest,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })
}

/**
 * POST form表单 求情
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const formPost = (url, data = {}, config = {}) => {
  const { headers = {}, ...configRest } = config
  return $http.post(url, qs.stringify(transformSpace(data)), {
    ...configRest,
    headers: {
      ...headers,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * POST 文件上传请求
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const postFile = (url, data, config = {}) => {
  return $http.post(url, data, config)
}
/**
 * GET 文件下载请求
 * @param {*} url
 * @param {*} params
 * @returns
 */
export const downloadGet = (url, params = {}, config = {}, name) => {
  const { headers = {}, ...configRest } = config
  return $http
    .get(url, {
      params,
      ...configRest,
      responseType: 'blob',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
    })
    .then(async(res) => {
      const json = await downloadHandle(res, name)
      return json
    })
}

/**
 * POST 文件下载json请求
 * @param {*} url
 * @param {*} data
 * @returns
 */
export const downloadPost = (url, data = {}, config = {}, name) => {
  const { headers = {}, ...configRest } = config
  return $http
    .post(url, typeof data === 'object' ? JSON.stringify(transformNull(data)) : data, {
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      ...configRest,
      responseType: 'blob',
    })
    .then(async(res) => {
      const json = await downloadHandle(res, name)
      return json
    })
}

/**
 * 以链接形式下载(此方法不能处理下载异常),OSS下载
 * @param {*} url
 * @param {*} data
 * @returns
 */

export const downloadOss = url => {
  window.location.href = `${OSSURIPrefix}${url}`
}


/**
 * 此方法下载要确保文件存在，或者不会返回错误提示，否则应该用 downloadGet
 */
export const downloadLink = url => {
  window.location.href = `/hriceErp/${url}`
}

/**
 * 此方法下载要确保文件存在，或者不会返回错误提示，否则应该用 downloadGet
 */
export const downloadData = (url, data = {}) => {
  window.location.href = `/hriceErp/${url}?${qs.stringify(data)}`
}

export const downloadFile = (data = {}) => {
  window.location.href = `/hriceErp/${downloadFileUrl1}?${qs.stringify(data)}`
}

import { $Info } from '../utils/tipUtil'
export const printHttp = (url, data) => {
  return new Promise((resovle) => {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.onload = (res) => {
      var text = res.target.responseText || res.target.response
      resovle(text)
    }
    xhr.onerror = (err) => {
      console.log(err)
      $Info.error(i18n.t('打印失败'))
    }
    xhr.send(data)
  })
}
