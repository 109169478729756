// 是否有消息提示框标志，防止出现多个影响体验， false 无 / true 有
let MSGSHOWFLAG = false

import { Message, Notification } from 'element-ui'
import i18n from "@/lang/index"

/**
 *
 * @param {*} message
 * @param {*} type success/warning/info/error
 * @returns
 */
export const showMessage = (message, type = 'info', duration = 3000) => {
  if (MSGSHOWFLAG) {
    return
  }
  MSGSHOWFLAG = true
  Message[type]({
    message,
    duration,
  })
  setTimeout(() => {
    MSGSHOWFLAG = false
  }, duration)
}

/**
 *
 * @param {*} message
 * @param {*} title
 * @param {*} type success/warning/info/error
 * @param {*} duration
 * @returns
 */
export const showToast = (message, title = i18n.t('消息'), type = 'info', duration = 3500) => {
  if (MSGSHOWFLAG) {
    return
  }
  MSGSHOWFLAG = true
  Notification[type]({
    title,
    message,
    duration,
    onClose: () => {
      MSGSHOWFLAG = false
    },
  })
  setTimeout(() => {
    MSGSHOWFLAG = false
  }, duration)
}
