<template>
  <div class="simple-table-wrap">
    <VxeTable
      ref="table"
      :data="data"
      highlight-hover-row
      :tooltip-config="{
        enterDelay: 100,
        leaveDelay: 100,
        enterable: true
      }"
      :row-config="rowConfig"
      :column-config="columnConfig"
      :empty-text="$t('暂无数据')"
      min-height="36px"
      v-bind="$attrs"
      @checkbox-change="selectionChange"
      @checkbox-all="selectionChange"
      @radio-change="radioChange"
      @cell-click="cellClick"
      @current-change="currentChange"
      @resizable-change="resizableChange"
    >
      <VxeColumn
        v-if="index"
        key="seqIndex"
        fixed="left"
        type="seq"
        :title="$t('序号')"
        align="center"
        width="60"
        footer-align="left"
      />
      <VxeColumn
        v-if="types"
        :key="types"
        :type="types"
        fixed="left"
        title=""
        align="center"
        width="60"
      />
      <VxeColumn
        v-if="showAction"
        key="action"
        fixed="left"
        :title="$t('操作')"
        :width="actionWidth"
      >
        <template #default="{ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, _columnIndex }">
          <slot
            name="action"
            :row="row"
            :row-index="rowIndex"
            :column="column"
            :column-index="columnIndex"
          />
        </template>
      </VxeColumn>
      <slot />
      <VxeColumn
        v-for="column1 of columns"
        :key="column1.field"
        v-bind="column1"
        :min-width="column1.minWidth||'100px'"
      >
        <template v-if="$slots[column1.field]" #default="{ row, rowIndex, $rowIndex, column, columnIndex, $columnIndex, _columnIndex }">
          <slot
            :name="column1.field"
            :row="row"
            :row-index="rowIndex"
            :column="column"
            :column-index="columnIndex"
          />
        </template>
        <template v-else-if="column1.hideText" #default="{ row,rowIndex }">
          <HideableText :text="row[column1.field]" :hide-positon="$getHidePosition(column1.hideText.idType, column1.hideText.country || row.documentType || row.entChinaFlag)" />
          <template v-if="!row[column1.field]">{{ column1.hideText.default }}</template>
      </template>
      </VxeColumn>
    </VxeTable>
  </div>
</template>

<script>
export default {
  name: 'SimpleTable',
  props: {
    // 是否显示序号
    index: {
      type: Boolean,
      default: true,
    },
    // 复选框  || 单选框 checkbox, radio
    types: {
      type: String,
      default: '',
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    actionWidth: {
      type: [String, Number],
      default: '160px',
    },
    // table 列字段配置
    columns: {
      type: Array,
      default() {
        return []
      },
    },
    rowConfig: {
      type: Object,
      default() {
        return {
          useKey: 'id',
          isCurrent: true,
          isHover: true,
        }
      },
    },
    columnConfig: {
      type: Object,
      default() {
        return {
          useKey: 'id',
          resizable: true,
          minWidth: 60,
        }
      },
    },
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    selectionChange({ records }) {
      this.$emit('selectionChange', records)
    },
    radioChange({ newValue, rowIndex }) {
      this.$emit('radioChange', newValue, rowIndex)
    },
    cellClick(scope) {
      this.$emit('cell-click', scope)
    },
    currentChange(scope) {
      this.$emit('current-change', scope)
    },
    headerCellStyle() {
      return 'background-color: rgb(184, 220, 255)'
    },
    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row)
    },
    resizableChange() {
      this.refreshColumn()
    },
    updateFooter() {
      this.$nextTick(() => {
        this.$refs.table && this.$refs.table.updateFooter()
        this.refreshColumn()
      })
    },
    // 刷新 列， 去除错位问题
    refreshColumn() {
      this.$nextTick(() => {
        this.$refs.tableRef && this.$refs.tableRef.refreshColumn()
        this.$refs.tableRef && this.$refs.tableRef.recalculate()
      })
    },
  },
}
</script>

<style lang="scss">
.simple-table-wrap {
  .vxe-table thead th {
    background-color: rgb(241, 244, 249);
    color: #515a6e;
  }

}
.vxe-table--tooltip-wrapper{
  z-index: 10000!important;
}
</style>
