import { showToast } from './message'
import store from '@/store/store'
import { getExportName, saveFile } from './saveFile'
import i18n from '@/lang/index'

// 响应状态码 弹框处理
// 5008021 客户管理 二次确认弹框code
export const responseHandle = ({ code, message }) => {
  switch (code) {
    case 0: // 请求成功
    case 5008021: // 二次确认弹框code
    case 5016001:
    case 5016011:
    case 5018048:
    case 5018058:
    case 5018054:
    case 5018056:
    case 5018057:
    case 5014032:
    case 5028017: // 导入工资单
    // case 5028005:
    // case 5028006:
    // case 5028007:
      break
    case 101: // 登录超时，返回 login 页面
      showToast(message)
      store.commit('backLogin')
      break
    default:
      showToast(message)
      break
  }
}

// 下载文件流处理
export const downloadHandle = async(res, name) => {
  // 当前下载的不是正常的文件流，而是后端返回的 json 提示
  try {
    const text = await res.data.text()
    const json = JSON.parse(text)
    responseHandle(json)
    return json
  } catch (_error) {
    const filename = name || getExportName(res)
    saveFile(res.data, filename)
    return {
      code: 0,
      message: i18n.t('导出成功'),
    }
  }
}
