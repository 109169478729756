<template>
  <ElFormItem label-width="0">
    <ElInput
      v-if="item.type === 'input'"
      v-model="currentValue"
      :style="style"
      :class="{'is-fouce': isFouceClass}"
      @focus="onFocus"
      @blur="onBlur"
      @keyup.enter.native="$parent.$parent.submit()"
    />
    <el-autocomplete
      v-else-if="item.type === 'autocomplete'"
      v-model="currentValue"
      :fetch-suggestions="querySearch"
      :style="style"
      :class="{'is-fouce': isFouceClass}"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #default="scope">
        {{ scope.item.label }}
      </template>
    </el-autocomplete>
    <NumberRange v-if="item.type === 'numberRange'" v-model="currentValue" :style="style" />
    <ElSelect
      v-else-if="item.type === 'select'"
      v-model="currentValue"
      class="erp-form-search-select"
      popper-class="select-ellipsis"
      filterable
      placeholder=""
      :clearable="item.clearable === false ? item.clearable : true"
      :multiple="!!item.multiple"
      reserve-keyword
      collapse-tags
      :style="style"
      :loading="loadingSelect"
      :remote="item.remote || false"
      :remote-method="remoteMethodSelect"
      :class="{'is-fouce': isFouceClass}"
      @focus="onFocus"
      @blur.capture.native="onBlur"
    >
      <ElOption
        v-for="i in setList"
        :key="i[item.setProp || 'value']"
        :label="i[item.setLabel || 'name']"
        :value="i[item.setProp || 'value']"
      />
    </ElSelect>
    <ElDatePicker
      v-if="item.type === 'ymd'"
      v-model="currentValue"
      :clearable="item.clearable === false ? item.clearable : true"
      :style="style"
      type="date"
      :editable="false"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      :class="{'is-fouce': isFouceClass}"
      @focus="onFocus"
      @blur="onBlur"
    />
    <ElDatePicker
      v-if="item.type === 'ym'"
      v-model="currentValue"
      :clearable="item.clearable === false ? item.clearable : true"
      :style="style"
      type="month"
      :editable="false"
      format="yyyy-MM"
      value-format="yyyy-MM"
      :class="{'is-fouce': isFouceClass}"
      @focus="onFocus"
      @blur="onBlur"
    />
    <ElDatePicker
      v-if="item.type === 'y'"
      v-model="currentValue"
      :clearable="item.clearable === false ? item.clearable : true"
      :style="style"
      type="year"
      :editable="false"
      format="yyyy"
      value-format="yyyy"
      :class="{'is-fouce': isFouceClass}"
      :picker-options="item.pickerOptions ? { ...item.pickerOptions} : {}"
      @focus="onFocus"
      @blur="onBlur"
    />
    <ElDatePicker
      v-if="item.type === 'ymd_2'"
      v-model="currentValue"
      :style="style"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      type="daterange"
      :editable="false"
      unlink-panels
      :class="{'is-fouce': isFouceClass}"
      :picker-options="item.pickerOptions ? {...ymd2chklist, ...item.pickerOptions} : ymd2chklist"
      :clearable="item.clearable === false ? item.clearable : true"
      @focus="onFocus"
      @blur="onBlur"
      @change="changeHandle"
    />
    <ElDatePicker
      v-if="item.type === 'ymd_hms_2'"
      v-model="currentValue"
      :style="style"
      format="yyyy-MM-dd HH:mm:ss"
      value-format="yyyy-MM-dd HH:mm:ss"
      type="datetimerange"
      :editable="false"
      unlink-panels
      :class="{'is-fouce': isFouceClass}"
      :default-time="['00:00:00', '23:59:59']"
      :picker-options="item.pickerOptions ? {...ymd2chklist, ...item.pickerOptions} : ymd2chklist"
      :clearable="item.clearable === false ? item.clearable : true"
      @focus="onFocus"
      @blur="onBlur"
    />
    <ElDatePicker
      v-if="item.type === 'ym_2'"
      v-model="currentValue"
      :style="style"
      format="yyyy-MM"
      value-format="yyyy-MM"
      type="monthrange"
      unlink-panels
      :class="{'is-fouce': isFouceClass}"
      :picker-options="item.pickerOptions ? item.pickerOptions : {}"
      :editable="false"
      :clearable="item.clearable === false ? item.clearable : true"
      @focus="onFocus"
      @blur="onBlur"
    />
    <ElCascader
      v-else-if="item.type === 'cascader'"
      :ref="`cascader${item.prop}`"
      v-model="currentValue"
      :options="setList"
      :show-all-levels="false"
      filterable
      placeholder=""
      :props="item.cascaderProps||setProps"
      :collapse-tags="!!item.cascaderProps"
      :style="style"
      :class="{'is-fouce': isFouceClass}"
      :clearable="item.clearable === false ? item.clearable : true"
      :popper-append-to-body="false"
      @focus="onFocus"
      @blur="onBlur"
      @change="changeClose"
    />

    <span class="inner-label">{{ item.label }}</span>
    <div v-if="item.tipText" style="font-size: 11px;line-height: 20px;color: #ca0a0a;">
      {{ item.tipText }}
    </div>
  </ElFormItem>
</template>
<script>
import { post, get } from '@/http/request'
import NumberRange from '../NumberRange.vue'
import { getUserOptionList, getDeptCascader } from '@/apis/common'

export default {
  name: 'Pop',
  components: { NumberRange },
  props: {
    value: {
      type: [String, Boolean, Object, Array, Number, Date],
      default: '',
    },
    width: {
      type: [String, Number],
      default: '210',
    },
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cascaderValue: [],
      ymd2chklist: {
        shortcuts: [
          {
            text: this.$t('最近一周'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$t('最近一个月'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: this.$t('最近三个月'),
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      setList: [],
      loadingSelect: false,
      setProps: {
        checkStrictly: true,
        value: 'id',
        children: 'children',
        label: 'name',
        emitPath: false,
      },
      isFouce: false,
    }
  },
  computed: {
    isFouceClass() {
      const isFouce = this.isFouce
      if (isFouce) {
        return true
      }
      const value = this.value
      if (Array.isArray(value)) {
        return value.length
      }
      return value || value === 0
    },
    currentValue: {
      // 动态计算currentValue的值
      get() {
        return this.value // 将props中的value赋值给currentValue
      },
      set(val) {
        this.$emit('input', (val || val === 0) ? val : null) // 通过$emit触发父组件
      },
    },
    styleWidth() {
      return this.item.width || this.width
    },
    style() {
      let styleWidth = this.width // this.item.width ||
      return {
        width: `${styleWidth}px`,
      }
    },
  },
  watch: {
    item() {
      this.searchItems()
    },
    'item.list'() {
      this.searchItems()
    },
  },
  created() {
    this.searchItems()
  },
  methods: {
    onFocus() {
      this.isFouce = true
    },
    onBlur() {
      setTimeout(() => {
        this.isFouce = false
      }, 100)
    },
    querySearch(keyword, cb) {
      if (!keyword) {
        return cb([])
      }
      this.item.getRemoteMethod(keyword, cb)
    },
    remoteMethodSelect(val) {
      this.setList = []
      this.loadingSelect = true
      const success = (data) => {
        this.setList = data
        this.loadingSelect = false
      }
      return this.item.getRemoteMethod(val, success)
    },
    searchItems() {
      this.getListAjax(this.item)
    },
    changeClose() {
      this.$refs[`cascader${this.item.prop}`].dropDownVisible = false
      if(this.item.finalProp){
        this.$nextTick(()=>{
          const getParentId=this.changeSetParentId()
          this.$emit('getParentId',this.item.finalProp,getParentId)

        })
        
      }
    },
    restText() {
      if (this.item.type == 'cascader') {
        this.currentValue = ''
        this.cascaderValue = []
      }
    },
    getListAjax(val) {
      if (val.url) {
        if (this.item.props) {
          this.setProps = this.item.props
        }
        if (val.url === 'sys/user/option') {
          getUserOptionList().then(data => {
            this.handleData(val, {
              data,
            })
          })
          return
        }
        if (val.url === 'sys/dept/cascader/list') {
          getDeptCascader().then(data => {
            this.handleData(val, {
              data,
            })
          })
          return
        }
        if (val.reqType == 'post') {
          post(val.url, val.parms).then((res) => {
            if (res.code === 0) {
              this.handleData(val, res)
            }
          })
          return
        }
        get(val.url, val.parms).then((res) => {
          if (res.code === 0) {
            this.handleData(val, res)
          }
        })
        return
      }
      this.setList = val.list
    },
    handleData(val, res) {
      if (val.getListProp) {
        this.setList = Object.freeze(val.getListProp(res))
      } else {
        this.setList = Object.freeze(res.data)
      }
    },
    changeHandle() {
      if (this.item.search) {
        this.$parent.$parent.submit()
      }
    },
    //返回的是父级id，不是最后一级的id
    changeSetParentId() {
      let checkedList = this.$refs[`cascader${this.item.prop}`].getCheckedNodes()
      checkedList = checkedList.filter(option => !(option.parent && option.parent.checked))
      const parentId=checkedList.map(({ value }) => value)
      return parentId

    },
  },
}
</script>

<style lang="scss">
.erp-form-search-select.el-select{
  .el-tag{
    max-width: 160px;
  }
}
</style>
