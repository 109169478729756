<template>
  <ElUpload
    class="upload-demo"
    action="/hriceErp/file/OSSUpload/reimbursement"
    accept=".png, .jpeg, .jpg, .xlsx, .xls, .pdf, .doc, .docx, .rar, .zip"
    :before-upload="beforeUpload"
    :http-request="changeFn"
    :on-preview="previewFn"
    :on-remove="removeFn"
    :file-list="fileList"
  >
    <ElButton v-if="showBtn && !icon" size="small" type="primary"> {{$t('点击上传')}}
    </ElButton>
    <i v-if="showBtn && !!icon" class="el-icon-upload" style="color: #3f82fd;"> {{$t('点击上传')}}
    </i>
  </ElUpload>
</template>
<script>
import { downloadOss, postFile } from '@/http/request'
import { isOutLimit } from '@/utils/validate.js'
import { regExpMatch } from '@/utils/common'
export default {
  name: 'OssUpload',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    type: {
      type: [String],
      default: '',
    },
    showBtn: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      fileList: this.list,
    }
  },
  watch: {
    list: {
      handler(val) {
        this.fileList = val
      },
      deep: true,
    },
    fileList: function(val) {
      this.$emit('getFileList', val)
    },
  },
  mounted() {
    this.fileList = this.list
  },
  methods: {
    beforeUpload(file) {
      var reg = /^.*\.(jpg|jpeg|png|doc|docx|pdf|xls|xlsx|rar|zip)$/i
      if (!regExpMatch(reg, file.name)) {
        this.$Info.warning(this.$t('请上传word,图片或pdf或excel'))
        return false
      }
      if (isOutLimit(file.size, 20)) {
        this.$Info.warning(this.$t('上传文件大小不可超过20M'))
        return false
      }
    },
    changeFn(e) {
      this.loading = true
      let deviceFile = e.file
      let formData = new FormData()
      formData.append('file', deviceFile)
      postFile(`file/OSSUpload/${this.type}`, formData)
        .then(res => {
          if (res.code == 0) {
            this.$Info.success(this.$t('上传成功'))
            this.fileList.push(res.data)
          } else {
            this.$Info.error(res.message)
          }
          this.loading = false
        })
    },
    previewFn(file) {
      downloadOss(file.url)
    },
    removeFn(file, fileList) {
      this.fileList = fileList
    },
  },
}
</script>
